import styled from 'styled-components';
import popUp from "../../styles/popup";
import { safeArea } from "../../styles/mixins";
import { NoStyleBtn } from "../../styles/buttons";
import { breakpoints, colors, fonts } from "../../styles/variables";
export var Wrapper = styled.div.withConfig({
  displayName: "style__Wrapper",
  componentId: "r30smn-0"
})(["min-width:80px;position:relative;line-height:0;@media (min-width:", "){min-width:180px;}"], breakpoints.md);
export var PopupWrapper = styled.div.withConfig({
  displayName: "style__PopupWrapper",
  componentId: "r30smn-1"
})(["", " ", " @media (min-width:", "){position:absolute;width:auto;height:auto;z-index:40;", " left:0;right:0;}"], popUp.wrapper, popUp.fadeInOut, breakpoints.md, function (props) {
  return props.position === 'bottom' ? "\n      top: 0; bottom: auto;\n    " : "\n      bottom: 0; top: auto;\n    ";
});
export var Overlay = styled.div.withConfig({
  displayName: "style__Overlay",
  componentId: "r30smn-2"
})(["", " @media (min-width:", "){display:none;}"], popUp.overlay, breakpoints.md);
export var ContentWrapper = styled.div.withConfig({
  displayName: "style__ContentWrapper",
  componentId: "r30smn-3"
})(["", " @media (min-width:", "){display:block;width:auto;height:auto;}"], popUp.contentWrapper, breakpoints.md);
export var Content = styled.div.withConfig({
  displayName: "style__Content",
  componentId: "r30smn-4"
})(["", " max-height:300px;padding:0;", " ", " transform-origin:50% 100%;@media (min-width:", "){padding:0;border-radius:4px;width:auto;height:auto;transform-origin:", ";}"], popUp.content, safeArea('bottom', 'padding-bottom'), popUp.scaleInOut, breakpoints.md, function (props) {
  return props.position === 'bottom' && '50% 0%';
});
export var SelectBtn = styled(NoStyleBtn).withConfig({
  displayName: "style__SelectBtn",
  componentId: "r30smn-5"
})(["&&{display:flex;flex-direction:column;align-items:stretch;background:", ";border:solid 1px ", ";text-align:left;border-radius:4px;line-height:0;svg{flex:none;margin-left:12px;", "}&:active,&:hover{background:", ";}width:100%;padding:", ";", " color:", ";font-weight:bold;", "}"], colors.white, function (props) {
  return props.formStyle ? colors.gray60 : colors.gray40;
}, function (props) {
  return props.position === 'top' && "transform: rotate(180deg) translateY(2px);";
}, colors.gray20, function (props) {
  return props.formStyle ? '16px' : '8px';
}, fonts.B14, colors.gray60, function (props) {
  return props.disabled && "\n      cursor: default;\n    ";
});
export var SelectBtnMain = styled.div.withConfig({
  displayName: "style__SelectBtnMain",
  componentId: "r30smn-6"
})(["width:100%;display:flex;align-items:center;"]);
export var LanguageText = styled.p.withConfig({
  displayName: "style__LanguageText",
  componentId: "r30smn-7"
})(["margin:0;margin-right:auto;"]);
export var LanguageName = styled.span.withConfig({
  displayName: "style__LanguageName",
  componentId: "r30smn-8"
})(["line-height:normal;"]);
export var OptionsList = styled.ul.withConfig({
  displayName: "style__OptionsList",
  componentId: "r30smn-9"
})(["width:100%;overflow:hidden;margin:0;padding:16px 0;list-style:none;@media (min-width:", "){padding:0;}"], breakpoints.md);
export var Option = styled.li.withConfig({
  displayName: "style__Option",
  componentId: "r30smn-10"
})(["cursor:pointer;", " padding:16px 32px;&:focus{font-weight:bold;}&:focus{background-color:", ";}@media (min-width:", "){padding:8px;}"], fonts.R14, colors.gray20, breakpoints.md);
export var Divisor = styled.div.withConfig({
  displayName: "style__Divisor",
  componentId: "r30smn-11"
})(["", " padding:16px;"], fonts.R11);