export var MigrationModalLabels = {
  withMembership: {
    title: 'migration.modal_club.title',
    description: 'migration.modal_club.description',
    cancel_button: 'status.cancel',
    confirm_button: 'migration.modal_club.confirm_button'
  },
  withoutMembership: {
    title: 'migration.modal_without_membership.title',
    description: 'migration.modal_without_membership.description',
    cancel_button: 'status.cancel',
    confirm_button: 'migration.modal_without_membership.confirm_button'
  }
};