import _typeof from "/drone/src/node_modules/next/node_modules/@babel/runtime/helpers/esm/typeof";
import * as LocalStorage from "../local-storage";
var PROCESSING_KEY = '_processing';
export var getUserKey = function getUserKey(userId) {
  return "".concat(userId).concat(PROCESSING_KEY);
};
export var addProcessingPost = function addProcessingPost(spark) {
  var userId = spark.userId;
  if (!userId) return;
  var processingSparks = getProcessingSparks(userId);
  processingSparks.push(spark);
  setProcessingSparks(userId, processingSparks);
};
export var getProcessingSparks = function getProcessingSparks(userId) {
  if (!userId) return [];
  var userKey = getUserKey(userId);
  var storedString = LocalStorage.get(userKey);
  var parsedSparks = storedString && JSON.parse(storedString);
  return Array.isArray(parsedSparks) ? parsedSparks : [];
};
export var setProcessingSparks = function setProcessingSparks(userId, sparks) {
  if (!userId) return;
  var userKey = getUserKey(userId);
  if (!sparks || sparks === null || _typeof(sparks) !== 'object' || sparks.length === 0) {
    LocalStorage.remove(userKey);
  } else {
    var _stringifiedSparks = JSON.stringify(sparks);
    LocalStorage.set(userKey, _stringifiedSparks);
  }
};