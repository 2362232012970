import { useState, useEffect } from 'react';
var useMediaQuery = function useMediaQuery(query) {
  var _useState = useState(false),
    doesMatch = _useState[0],
    onSetDoesMatch = _useState[1];
  useEffect(function () {
    var onUpdateMatch = function onUpdateMatch(_ref) {
      var matches = _ref.matches;
      onSetDoesMatch(matches);
    };
    var matcher = window.matchMedia(query);
    var isModern = ('addEventListener' in matcher);
    if (isModern) {
      matcher.addEventListener('change', onUpdateMatch);
    } else {
      matcher.addListener(onUpdateMatch);
    }
    onUpdateMatch(matcher);
    return function () {
      if (isModern) {
        matcher.removeEventListener('change', onUpdateMatch);
      } else {
        matcher.removeListener(onUpdateMatch);
      }
    };
  }, [query, onSetDoesMatch]);
  return doesMatch;
};
export default useMediaQuery;